<template>
  <div class="content_mesage">
    <div class="content_crumbs">
      <el-breadcrumb separator-icon="ArrowRight">
        <el-breadcrumb-item :to="{ path: '/' }">当前位置：集团要闻</el-breadcrumb-item>
        <el-breadcrumb-item>正文</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="content_tip">
      <!-- 循环盒子 -->
      <div class="content_tip_title" v-for="item in Table.value" :key="item.id" @click="setHtml(item)">
        <div class="content_tip_mesage">
          <div class="mesage_title">{{item.description}}</div>
          <div class="mesage_content" v-html="$options.filters.ellipsis(item.content)"></div>
          <div class="mesage_time">{{item.createTime.slice(0,11)}}</div>
        </div>
        <div class="content_tip_pic">
          <img :src="item && item.thumbnailUrlList && item.thumbnailUrlList[0] ? item.thumbnailUrlList[0].link : ''" alt="" class="img_pic">
        </div>
      </div>
      <div style="display:flex;justify-content: flex-end;padding-bottom:20px;">
        <el-pagination background v-model:currentPage="currentPage" v-model:page-size="pageSize" :page-sizes="[10, 20, 40]" layout="total, sizes, prev, pager, next, jumper" :total="pagetotal"
          @size-change="handleSizeChange" @current-change="handleCurrentChange" />
      </div>
    </div>
  </div>
</template>

<script>
import imgData from "@/data.js";
import { reactive, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { stateStore } from "@/store";
import { getNewsTable } from "@/api/index";
export default {
  filters: {
    ellipsis(value) {
      value = value.replace(/<\/?.+?>/g, "");
      value = value.replace(/ /g, " ");
      value = value.replace(/.*?\}/g, " ");
      value = value.replace(/.*?\}/g, "");
      return value;
    },
  },
  setup() {
    const router = useRouter();
    const currentPage = ref(1)
    const pageSize = ref(10)
    let pagetotal = ref(1)
    const Table = reactive([])
    const handleSizeChange = (val) => {
      pageSize.value = val
      pageTable()
    }
    const handleCurrentChange = (val) => {
      currentPage.value = val
      pageTable()
    }
    const setHtml = (item) => {
      stateStore().setactiveHtml(
        item.content,
        item.title,
        item.createTime,
        item
      );
      router.push({ path: "/InfoView", query: { id: item.id } })
    };
    const pageTable = async () => {
      const res = await getNewsTable({
        current: currentPage.value || 1,
        size: pageSize.value || 10,
        id: '1726797257541849089',
      });
      pagetotal.value = res.data.total
      Table.value = res.data.records
    };

    onMounted(async () => {
      await pageTable();
    });
    const data = reactive({
      handleSizeChange,
      handleCurrentChange,
      pageTable,
      Table,

    });
    return {
      ...data,
      ...imgData,
      pagetotal,
      activePage: "/index",
      haveToken: false,
      currentPage,
      pageSize,
      setHtml
    };
  },
  created() {
    let href = window.location.href;
    let origin = window.location.origin + "/";
    this.activePage =
      href.replace(origin, "") == "BiddingInfo"
        ? ""
        : "/" + href.replace(origin, "");

    let arr = document.cookie.split(";");
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].indexOf("saber-access-token") !== -1) {
        this.haveToken = true;
      }
    }
  },
  methods: {

  },
};
</script>

<style scoped>
@import url("./conMes.css");
</style>